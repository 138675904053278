import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Container, BackDrop, Form } from "./styles";
import PasswordInput from "../PasswordInput";

function PasswordProtected({ poster, query, unlock }) {
  const [password, setPassword] = useState("");
  const { t } = useTranslation();

  const handlePasswordChange = useCallback(
    (event) => {
      setPassword(event.target.value);
    },
    [setPassword]
  );

  const handleSubmitPassword = useCallback((event) => {
    event.preventDefault();
    let url = "";
    switch (query.env) {
      case "dev":
        url = `https://dev.fanheroapi.com/${query.get("type")}/${query.get(
          "id"
        )}/password-check`;
        break;
      case "prod":
        url = `https://api.fanheroapi.com/${query.get("type")}/${query.get(
          "id"
        )}/password-check`;
        break;
      default:
        url = `http://localhost:3002/${query.get("type")}/${query.get(
          "id"
        )}/password-check`;
        break;
    }
    fetch(`${url}?password=${password}`, {
      headers: {
        apiKey: query.get("apiKey"),
        organization: query.get("organization"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.correct) {
          alert(t("passwordProtectedWrongPassword"));
        } else {
          unlock(true);
        }
      })
      .catch((error) => {
        alert(`${t("passwordProtectedWrongPassword")}`, JSON.stringify(error));
      });
  // eslint-disable-next-line
  }, [password]);

  return (
    <Container poster={poster}>
      <BackDrop>
        <h1>{t("passwordProtectedPasswordTitle")}</h1>
        <h4>{t("passwordProtectedPasswordSubtitle")}</h4>
        <Form onSubmit={handleSubmitPassword}>
          <PasswordInput
            placeholder={t("passwordProtectedPasswordPlaceholder")}
            onInput={handlePasswordChange}
          />
          <button type="button" onClick={handleSubmitPassword}>
            {t("passwordProtectedPasswordSubmit")}
          </button>
        </Form>
      </BackDrop>
    </Container>
  );
}

export default PasswordProtected;
