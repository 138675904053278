import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d2dfe9;
  background: #FFF;
  box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 13px 15px;

  margin-right: 10px;

  @media (max-width: 767px) {
    margin-right: 0;
  }

  input {
    border: none;

    &::placeholder {
      font-size: 16px;
      color: #7f8fa4;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  button {
    border: none;
    background: #FFF;
    color: #7f8fa4;

    :hover {
      cursor: pointer;
    }

    :active,
    :focus,
    :visited {
      border: none;
      outline: none;
    }
  }
`;
