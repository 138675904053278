import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          // DomainPolicy
          domainPolicyTitle: 'Sorry',
          domainPolicySubtitle: 'Because of its privacy settings, this video cannot be played here.',

          // PasswordProtected
          passwordProtectedWrongPassword: 'Wrong password!',
          passwordProtectedPasswordPlaceholder: 'Password',
          passwordProtectedPasswordTitle: 'Password required',
          passwordProtectedPasswordSubtitle: 'If you have the password, insert it below.',
          passwordProtectedPasswordSubmit: 'Watch the video',
        },
      },
      'pt-BR': {
        translations: {
          // DomainPolicy
          domainPolicyTitle: 'Desculpe',
          domainPolicySubtitle: 'Por políticas de privacidade, este vídeo não pode ser repoduzido aqui.',

          // PasswordProtected
          passwordProtectedWrongPassword: 'Senha inválida!',
          passwordProtectedPasswordPlaceholder: 'Senha',
          passwordProtectedPasswordTitle: 'Senha necessária',
          passwordProtectedPasswordSubtitle: 'Se você tem a senha, insira-a abaixo.',
          passwordProtectedPasswordSubmit: 'Assistir ao vídeo',
        },
      },
    },
    // lng: 'pt_br',
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
