import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;

  ${({ poster }) => {
    return css`
      background-image: url('${poster}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `;
  }};

  h1 {
    font-size: 3rem;
    margin: 0;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 1.3rem;
    margin: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 2rem;
    }

    h4 {
      font-size: .8rem;
    }
  }
`;

export const BackDrop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
`;


export const Form = styled.form`
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0 40px;

    > button {
      margin-top: 10px;
      width: 100%;
    }
  }

  > button {
    padding: 13px 45px;
    height: auto;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #0066ff;
    background: #0066ff;
    color: #fff;
    border: none;

    :hover,
    :focus {
      background: '#0066ff';
      color: #fff;
      cursor: pointer;
    }
  }
`;