import React from "react";
import "./styles.css";

function Loader() {
  return (
    <>
      <div className="circle red"></div>
      <div className="circle green"></div>
      <div className="circle blue"></div>
    </>
  );
}

export default Loader;
