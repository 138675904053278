import React, { useCallback, useState } from 'react';

import { Container } from './styles';

import './styles.scss'

function PasswordInput(props) {
  const [isPasswordInput, setIsPasswordInput] = useState(true);

  const handleToggleButtonType = useCallback(() => {
    setIsPasswordInput(!isPasswordInput);
  }, [isPasswordInput, setIsPasswordInput]);

  return <Container>
    <input type={isPasswordInput ? 'password' : 'text'} {...props} />
    <button type="button" onClick={handleToggleButtonType}>
      <i className={isPasswordInput ? "far fa-eye" : "far fa-eye-slash"}></i>
    </button>
  </Container>;
}

export default PasswordInput;