import React, { useEffect } from "react";
import Player from "./components/Player";
import Loader from "./components/Loader";
import PasswordProtected from "./components/PasswordProtected";

const App = () => {
  const query = new URLSearchParams(window.location.search);
  const [src, setSrc] = React.useState(null);
  const [poster, setPoster] = React.useState("https://d1k5o3ezm3npyz.cloudfront.net/0x400/filters:format(webp)/filters:no_upscale()/filters:quality(70)/61ba2606c1805142c289377f/61ba2e33d066385027bccd20/624783b0332fa788b2a35346_1648853936068");
  const [protectedVideo, setProtectedVideo] = React.useState(false);
  const [checkedPassword, setCheckedPassword] = React.useState(false);
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
  };

  // eslint-disable-next-line
  useEffect(() => {
    let url;
    let mediaUrl;
    switch (query.get("env")) {
      case "dev":
        url = `https://dev.fanheroapi.com/${query.get("type")}${window.location.pathname
          }`;
          mediaUrl = "https://dev.fanheroapi.com/medias/";
        break;
      case "prod":
        url = `https://api.fanheroapi.com/${query.get("type")}${window.location.pathname
          }`;
          mediaUrl = "https://api.fanheroapi.com/medias/";
        break;
      default:
        url = `http://localhost:3002/${query.get("type")}${window.location.pathname
          }`;
          mediaUrl = "http://localhost:3002/medias/";
        break;
    }
    fetch(url, {
      headers: {
        apiKey: query.get("apiKey"),
        organization: query.get("organization"),
        channel: query.get("channel"),
      },
    })
      .then((res) => res.json())
      .then((content) => {
        query.set("id", content.id);
        setProtectedVideo(content.kind === "PRIVATE" ? true : false);
        // eslint-disable-next-line
        fetch(`${mediaUrl}${content.thumbnail}`, {
          headers: {
            apiKey: query.get("apiKey"),
            organization: query.get("organization"),
          },
        })
          .then((res) => res.json())
          .then((thumb) => {
            if (thumb) {
            setPoster(
              `${thumb.baseUrl}/0x1080/filters:format(webp)/filters:no_upscale()/filters:quality(70)/${thumb.imgPath}`
            );
            }
            if (query.get("type") === "posts") {
              fetch(`${mediaUrl}${content.media}`, {
                headers: {
                  apiKey: query.get("apiKey"),
                  organization: query.get("organization"),
                  channel: query.get("channel"),
                },
              })
                .then((res) => res.json())
                .then((media) => {
                  setSrc(`${media.baseUrl}/${media.hlsPath}`)
                })
            } else {
            setSrc(content.hlsPlaybackUrl);
            }
          });
      });
  }); // eslint-disable-next-line

  const getComponent = () => {
    if (protectedVideo && poster && !checkedPassword) {
      return (
        <PasswordProtected
          query={query}
          poster={poster}
          unlock={setCheckedPassword}
        />
      );
    }

    if (src && poster) {
      return (
        <Player
          options={{
            ...videoJsOptions,
            poster,
            sources: [
              {
                src,
                type: "application/x-mpegURL",
              },
            ],
          }}
        />
      );
    }

    return <Loader />;
  };

  return <>{getComponent()}</>;
};

export default App;
